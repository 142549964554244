<template>
  <div>
    <section class="content-header">
      <div class="btn btn-group">
        <button
          type="button"
          class="btn btn-sm btn-info"
          data-toggle="modal"
          data-target="#modal-form-tarifariomanual"
          @click="abrirModal()"          
          v-if="
            $store.getters.can(
              'hidrocarburos.contratos.tarifariosmanuales_create'
            )
          "
        >
          Agregar Tarifario
        </button>
      </div>
    </section>
    <section id="table">
      <div class="col-md-12 form-group">
        <table
          id="tabla-tarifas"
          class="table table-striped table-bordered table-hover table-sm"
        >
          <thead class="thead-dark">
            <tr>
              <th class="text-center">Fecha Inicio</th>
              <th class="text-center">Fecha Fin</th>
              <th class="text-center">Aprob. Transportadora</th>
              <th class="text-center">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="manualTarifario in manualTarifarios"
              :key="manualTarifario.id"
            >
              <td class="text-center">
                {{ manualTarifario.fecha_inicio }}
              </td>
              <td class="text-center">
                {{ manualTarifario.fecha_final }}
              </td>
              <td class="text-center">
                <span
                  class="badge"
                  v-bind:class="[
                    manualTarifario.aprobaciones_tarifarios_manuales
                      .aprobacionTransportadora.length > 0
                      ? 'badge-success'
                      : 'badge-danger',
                  ]"
                >
                  {{
                    manualTarifario.aprobaciones_tarifarios_manuales
                      .aprobacionTransportadora.length > 0
                      ? "Si"
                      : "No"
                  }}
                </span>
              </td>
              <td class="text-center">
                <div class="btn btn-group text-rigth">
                  <button
                    type="button"
                    class="btn btn-sm bg-olive"
                    data-toggle="modal"
                    data-target="#modal-form-adjunto"
                    v-if="
                      !manualTarifario.link_documento &&
                      $store.getters.can(
                        'hidrocarburos.contratos.tarifariosmanuales_edit'
                      )
                    "
                    @click="instancia(manualTarifario.id)"
                  >
                    <i
                      class="fa fa-upload"
                      v-b-popover.hover.left=""
                      title="Cargar Documento"
                    ></i>
                  </button>
                  <a
                    target="_blank"
                    class="btn btn-info btn-sm"
                    v-if="manualTarifario.link_documento"
                    :href="uri_docs + manualTarifario.link_documento"
                  >
                    <i
                      class="fa fa-download"
                      v-b-popover.hover.left=""
                      title="Descargar Documento"
                    ></i>
                  </a>
                  <button
                    type="button"
                    class="btn btn-sm bg-navy"
                    data-toggle="modal"
                    data-target="#modal-form-firmasgeneral"
                    :disabled="!manualTarifario.link_documento"
                    @click="getFirmas(manualTarifario)"
                  >
                    <i
                      class="fas fa-signature"
                      v-b-popover.hover.top=""
                      title="Firmar"
                    ></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-sm bg-primary"
                    @click="recordarFrima(manualTarifario)"
                  >
                    <i
                      class="fas fa-envelope-open-text"
                      v-b-popover.hover.rigth=""
                      title="Recordar Firma"
                    ></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>

    <!-- Modal de Tarifario Manual  -->
    <div class="modal fade" id="modal-form-tarifariomanual">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Tarifario Manual</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <!-----Inicio Input Fecha Inicial ----->
              <div class="form-group col-md-4">
                <label>Fecha inicial</label>
                <input
                  type="date"
                  class="form-control form-control-sm"
                  v-model="form.fecha_inicio"
                  :class="
                    $v.form.fecha_inicio.$invalid ? 'is-invalid' : 'is-valid'
                  "
                />
              </div>
              <!-----Fin Input Fecha Inicial ----->

              <!-----Inicio Input Fecha Final ----->
              <div class="form-group col-md-4">
                <label>Fecha final</label>
                <input
                  type="date"
                  class="form-control form-control-sm"
                  v-model="form.fecha_final"
                  :class="
                    $v.form.fecha_final.$invalid ? 'is-invalid' : 'is-valid'
                  "
                />
              </div>
              <!-----Fin Input Fecha Final ----->

              <!-----Inicio Input Usuario ----->
              <div class="form-group col-md-4">
                <label>Usuario</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  :value="responsable.nombre"
                  disabled
                />
                <input
                  v-model="form.user_id"
                  type="hidden"
                  class="form-control form-control-sm p-0"
                />
              </div>
              <!-----Fin Input Usuario ----->

              <!-----Inicio Textarea Observaciones ----->
              <div class="form-group col-md-6">
                <label for="observaciones">Observaciones</label>
                <br />
                <textarea
                  vtype="date"
                  class="form-control"
                  id="fecha_final"
                  v-model="form.observaciones"
                  rows="2"
                  :class="
                    $v.form.observaciones.$invalid ? 'is-invalid' : 'is-valid'
                  "
                ></textarea>
              </div>
              <!-----Fin Textarea Observaciones ----->
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              v-show="!$v.form.$invalid"
              @click="save()"
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal de Adjunto Tarifario Manual  -->
    <div class="modal fade" id="modal-form-adjunto">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Adjunto Archivo</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModalArchivo"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="form-group" v-if="!manualTarifarios.link_documento">
                <input
                  type="file"
                  id="archivo-adjunto"
                  class="form-control-file"
                  accept="image/x-png,image/gif,image/jpeg,application/pdf,"
                  @change="obtenerArchivo"
                />
              </div>
              <div class="form-group float-right" v-if="fileAdjunto">
                <button
                  type="button"
                  class="btn btn-secondary col-md-12"
                  @click="limpiarArchivo"
                >
                  <i class="fas fa-eraser"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              v-if="fileAdjunto"
              @click="saveArchivo()"
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal de Aprobación de Tarifarios Manuales ---->
    <div class="modal fade" id="modal-form-firmasgeneral">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Aprobación Tarifa</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModalFirma"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <!--- Firma Transportadora --->
              <div class="form-group col-md-12">
                <h5>Firma Transportadora</h5>

                <!-- Formulario para firmar -->
                <div
                  class="row"
                  v-if="
                    !firmas.transportadoraFlag.firmado &&
                    firmas.transportadoraFlag.permiso
                  "
                >
                  <div class="form-group col-md-12">
                    <div class="callout callout-warning">
                      <small
                        ><strong
                          >Para firmar la concilicación por favor digite su
                          clave.</strong
                        ></small
                      >
                    </div>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="sign_password">Contraseña</label>
                    <input
                      type="password"
                      class="form-control form-control-sm"
                      v-model="firmas.transportadoraForm.pass"
                      :class="
                        $v.firmas.transportadoraForm.pass.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="re_sign_password">Confirme Contraseña</label>
                    <input
                      type="password"
                      class="form-control form-control-sm"
                      v-model="firmas.transportadoraForm.re_pass"
                      :class="
                        $v.firmas.transportadoraForm.re_pass.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                  <div class="form-group col-md-12">
                    <label for="observaciones">Observaciones</label
                    ><textarea
                      vtype="date"
                      rows="2"
                      class="form-control form-control-sm"
                      v-model="firmas.transportadoraForm.observacion"
                      :class="
                        $v.firmas.transportadoraForm.observacion.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    ></textarea>
                  </div>
                  <div class="form-group col-md-12">
                    <button
                      type="button"
                      v-show="!$v.firmas.transportadoraForm.$invalid"
                      title="Guardar firma"
                      class="btn btn-sm bg-success"
                      data-toggle="tooltip"
                      v-if="firmas.transportadoraFlag.permiso"
                      @click="saveFirma(firmas.transportadoraForm)"
                    >
                      <i class="fas fa-signature"></i>
                      <div>
                        <span><small>Aprobar Tarifa</small></span>
                      </div>
                    </button>
                  </div>
                </div>
                <!-- Información de la firma -->
                <div class="row" v-if="firmas.transportadoraFlag.firmado">
                  <div class="form-group col-md-12">
                    <p>
                      Firmado por: <br />
                      <strong>{{
                        firmas.transportadoraFlag.data.usuario
                      }}</strong>
                    </p>
                    <p>
                      Fecha: <br />
                      <strong>{{
                        firmas.transportadoraFlag.data.fecha
                      }}</strong>
                    </p>
                    <p>
                      Observación: <br />
                      {{ firmas.transportadoraFlag.data.observacion }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, sameAs } from "vuelidate/lib/validators";
import { VBPopover } from "bootstrap-vue";

export default {
  name: "ContratoTarifarioManual",
  directives: {
    "b-popover": VBPopover,
  },
  data() {
    return {
      idmanualtarifario: "",
      contrato_id: null,
      tarifario_manual_id: null,
      manualTarifarios: [],
      fileAdjunto: null,
      archivoValido: true,
      link_documento: null,
      metodo: "",
      id: this.$route.params.id,
      form: {
        id: null,
        contrato_id: null,
        fecha_inicio: null,
        fecha_final: null,
        observaciones: null,
        user_id: this.$store.getters.getUser.id,
      },
      firmas: {
        transportadoraFlag: {
          firmado: false,
          permiso: false,
          data: null,
        },
        transportadoraForm: {
          tarifario_manual_id: null,
          tipo_aprobacion: "transportadora",
          observacion: null,
          pass: null,
          re_pass: null,
        },
      },
      responsable: {
        id: this.$store.getters.getUser.id,
        nombre: this.$store.getters.getUser.name,
      },
      listasForms: {
        estados: [],
      },
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  validations: {
    form: {
      fecha_inicio: {
        required,
      },
      fecha_final: {
        required,
      },
      observaciones: {
        required,
      },
    },
    firmas: {
      transportadoraForm: {
        pass: {
          required,
        },
        re_pass: {
          required,
          sameAsPassword: sameAs("pass"),
        },
        observacion: {
          required,
        },
      },
    },
  },
  methods: {
    async getIndex() {
      this.contrato_id = this.$parent.id;
      this.form.contrato_id = this.contrato_id;
      await axios
        .get("/api/hidrocarburos/tarifariosmanuales/show/" + this.contrato_id)
        .then((response) => {
          this.manualTarifarios = response.data[0].tarifariosmanuales;
          this.buscarAprobaciones();
        });
    },

    limpiarArchivo() {
      this.fileAdjunto = null;
      document.getElementById("archivo-adjunto").value = "";
    },

    obtenerArchivo(e) {
      this.fileAdjunto = null;
      this.archivoValido = false;
      if (e.target.files[0]) {
        this.cargando = true;
        const file = e.target.files[0];
        if (this.validaArchivo(file)) {
          this.archivoValido = true;
          this.fileAdjunto = file;
        }
      } else {
        this.archivoValido = true;
      }
    },

    validaArchivo(file) {
      let flag = true;
      if (file.size > 1000000) {
        flag = false;
        this.limpiarArchivo();
        this.$swal({
          icon: "error",
          title: `El Archivo es muy pesado para subirlo, seleccione un archivo de nuevo...`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      return flag;
    },

    saveArchivo() {
      this.cargando = true;
      let formData = new FormData();
      let me = this;
      formData.append("id", this.idmanualtarifario);
      formData.append("link_documento", this.fileAdjunto);
      axios
        .post("api/hidrocarburos/tarifariosmanuales/documento", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.getIndex();
          this.$refs.closeModalArchivo.click();
          this.$swal({
            icon: "success",
            title: "El archivo se guardó exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.archivoValido = false;
          (this.fileAdjunto = null),
            (this.link_documento = response.data.link_documento);
          this.cargando = false;
        })
        .catch(function (error) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    save() {
      this.cargando = true;
      if (!this.$v.form.$invalid) {
        axios({
          method: "PUT",
          url: "/api/hidrocarburos/tarifariosmanuales",
          data: this.form,
        })
          .then((response) => {
            this.$parent.form.estado = response.data.estado_contrato;
            this.$parent.estado = response.data.estado_contrato;
            this.form = {};
            this.getIndex();
            this.$refs.closeModal.click();
            this.cargando = false;
            this.$swal({
              icon: "success",
              title: "El tarifario se creó exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.form = {};
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vuelva a intentarlo... " + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    instancia(manualTarifarios) {
      this.idmanualtarifario = manualTarifarios;
    },

    validaFecha() {
      if (
        this.manualTarifarios.contratos.fecha_fin <
        this.manualTarifarios.fecha_final
      ) {
        this.$swal({
          title: "Revisar Fechas!!",
          text: "La fecha del contrato final no puede ser menor a la fecha final del tarifario",
          icon: "warning",
        });
      }
    },

    buscarAprobaciones() {
      this.manualTarifarios.forEach((tarifariomanual) => {
        let result = this.getAprobaciones(
          tarifariomanual.aprobaciones_tarifarios_manuales,
          tarifariomanual.id
        );
        tarifariomanual.aprobaciones_tarifarios_manuales.aprobacionTransportadora =
          result.aprobacionTransportadora;
      });
    },

    getAprobaciones(aprobacion, tarifario_manual_id) {
      let result = {
        aprobacionTransportadora: [],
      };
      aprobacion.forEach((element) => {
        if (tarifario_manual_id == element.tarifario_manual_id) {
          if (element.tipo_aprobacion == "transportadora") {
            result.aprobacionTransportadora = [element];
          }
        }
      });

      return result;
    },

    getFirmas(tarifario_manual) {
      let aprobaciones = tarifario_manual.aprobaciones_tarifarios_manuales;
      let tarifario_manual_id = tarifario_manual.id;
      const permisos_firmas = this.$parent.listasForms.permisos_firmas;

      /***** TRANSPORTADORA *****/
      // Se valida si ya la generadora autorizó la tarifa
      if (aprobaciones.aprobacionTransportadora.length > 0) {
        let array = aprobaciones.aprobacionTransportadora[0];
        this.firmas.transportadoraFlag.firmado = true;
        this.firmas.transportadoraFlag.data = {
          usuario: array.user.name,
          fecha: array.fecha_aprobacion,
          observacion: array.observacion,
        };
      } else {
        this.firmas.transportadoraFlag.firmado = false;
        // Se pregunta si el usuario logueado tiene permisos de firmar
        if (
          this.$store.getters.can("hidrocarburos.contratos.firmaTransportadora")
        ) {
          this.firmas.transportadoraFlag.permiso = true;
          this.firmas.transportadoraForm.tarifario_manual_id =
            tarifario_manual_id;
        } else {
          this.firmas.transportadoraFlag.permiso = false;
        }
      }
    },

    saveFirma(array) {
      let me = this;
      if (!this.$v.firmas.transportadoraForm.$invalid) {
        let pass = array.pass;
        let data = {
          contrato_id: this.contrato_id,
          tarifario_manual_id: array.tarifario_manual_id,
          observacion: array.observacion,
          tipo_aprobacion: array.tipo_aprobacion,
          user_id: this.$store.getters.getUser.id,
        };
        let form = { data, pass };

        // Se valida la url para hacer la firma
        let url = null;
        switch (array.tipo_aprobacion) {
          case "transportadora":
            url = "/api/hidrocarburos/tarifariosmanuales/firmaTransportadora";
            break;
        }

        axios({
          method: "PUT",
          url,
          data: form,
        })
          .then((response) => {
            me.$parent.form.estado = response.data.estado_contrato;
            me.$parent.estado = response.data.estado_contrato;
            this.getIndex();
            this.$refs.closeModalFirma.click();
            this.$swal({
              icon: "success",
              title: "El tarifario fue autorizado exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            me.$swal({
              icon: "error",
              title: "Ocurrió un error: " + e.response.data.message,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    abrirModal() {
      this.form.user_id = this.$store.getters.getUser.id;
    },

    recordarFrima(tarifario) {
      this.$swal({
        title: "Esta seguro de enviar este Recordatorio?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Enviar!",
      }).then((result) => {
        if (result.value) {
          let me = this;
          const data = {
            tarifario_manual_id: tarifario.id,
          };

          axios
            .post(
              "/api/hidrocarburos/tarifariosmanuales/emailRecordarorioFirma",
              {
                data: data,
              }
            )
            .then((response) => {
              const mensaje_correo = response.data;
              if (mensaje_correo.msg) {
                me.$swal({
                  icon: "success",
                  title: mensaje_correo.msg,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              }
              if (mensaje_correo.error) {
                me.$swal({
                  icon: "error",
                  title: "Ocurrió un error: " + mensaje_correo.error,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              }
            })
            .catch(function (error) {
              me.$swal({
                icon: "error",
                title: "Ocurrió un error" + error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },
  },

  mounted() {
    this.getIndex();
  },
};
</script>
