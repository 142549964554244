<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Contratos</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Basico</li>
                  <li class="breadcrumb-item active">Contratos</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="col-6 pt-2 pb-2">
                <h5 class="mb-0">Formulario {{ accion }} Contratos</h5>
              </div>
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="tab-Principal"
                    data-toggle="tab"
                    href="#Principal"
                    >Principal</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="tab-Tarifarios"
                    data-toggle="tab"
                    href="#Tarifarios"
                    v-if="id != null"
                    >Tarifarios</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="tab-TarifariosManuales"
                    data-toggle="tab"
                    href="#TarifariosManuales"
                    v-if="id != null"
                    >Tarifarios Manuales
                  </a>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade active show" id="Principal">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-9">
                        <div class="row">
                          <div class="form-group col-md-2">
                            <label for="fecha_inicial"># Contrato</label>
                            <input
                              type="text"
                              maxlength="30"
                              class="form-control form-control-sm"
                              id="no_contrato"
                              v-model="form.no_contrato"
                              :class="
                                $v.form.no_contrato.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              :disabled="
                                !$store.getters.can(
                                  'hidrocarburos.contratos.edit'
                                )
                              "
                            />
                          </div>
                          <div class="form-group col-md-4">
                            <label for="fecha_final">Empresa</label>
                            <v-select
                              :class="[
                                $v.form.empresa_id.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid',
                                $store.getters.getDarkMode
                                  ? 'dark-vselect'
                                  : '',
                              ]"
                              v-model="empresa"
                              placeholder="Empresas"
                              label="razon_social"
                              class="form-control form-control-sm p-0"
                              :options="listasForms.empresas"
                              :filterable="false"
                              @search="buscarEmpresas"
                              @input="selectEmpresa()"
                              :disabled="
                                !$store.getters.can(
                                  'hidrocarburos.contratos.edit'
                                )
                              "
                            ></v-select>
                          </div>
                          <div class="form-group col-md-3">
                            <label for="tipo_contrato">Tipo de Contrato</label>
                            <select
                              class="form-control form-control-sm"
                              v-model="form.tipo_contrato"
                              :class="
                                $v.form.tipo_contrato.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              :disabled="
                                !$store.getters.can(
                                  'hidrocarburos.contratos.edit'
                                )
                              "
                            >
                              <option value="">Seleccione...</option>
                              <option
                                v-for="tipo_contrato in listasForms.tipos_contrato"
                                :key="tipo_contrato.numeracion"
                                :value="tipo_contrato.numeracion"
                              >
                                {{ tipo_contrato.descripcion }}
                              </option>
                            </select>
                          </div>
                          <div class="form-group col-md-3">
                            <label for="objeto_contrato"
                              >Objeto de Contrato</label
                            >
                            <select
                              class="form-control form-control-sm"
                              v-model="form.objeto_contrato"
                              :class="
                                $v.form.objeto_contrato.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              :disabled="
                                !$store.getters.can(
                                  'hidrocarburos.contratos.edit'
                                )
                              "
                            >
                              <option value="">Seleccione...</option>
                              <option
                                v-for="objeto_contrato in listasForms.objetos_contrato"
                                :key="objeto_contrato.numeracion"
                                :value="objeto_contrato.numeracion"
                              >
                                {{ objeto_contrato.descripcion }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="row">
                          <div class="form-group col-md-3">
                            <label for="fecha_ini">Fecha Inicio</label>
                            <input
                              type="date"
                              class="form-control form-control-sm"
                              id="fecha_ini"
                              v-model="form.fecha_ini"
                              :class="
                                $v.form.fecha_ini.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              @input="validaFechas"
                              :disabled="
                                !$store.getters.can(
                                  'hidrocarburos.contratos.edit'
                                )
                              "
                            />
                          </div>
                          <div class="form-group col-md-3">
                            <label for="fecha_fin">Fecha Fin</label>
                            <input
                              type="date"
                              class="form-control form-control-sm"
                              id="fecha_fin"
                              v-model="form.fecha_fin"
                              :class="
                                $v.form.fecha_fin.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              @input="validaFechas"
                              :disabled="
                                !$store.getters.can(
                                  'hidrocarburos.contratos.edit'
                                )
                              "
                            />
                          </div>
                          <div class="form-group col-md-3">
                            <label for="valor">Valor</label>
                            <input
                              type="number"
                              class="form-control form-control-sm"
                              id="valor"
                              v-model="form.valor"
                              :class="
                                $v.form.valor.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              :disabled="
                                !$store.getters.can(
                                  'hidrocarburos.contratos.edit'
                                )
                              "
                            />
                          </div>
                          <div class="form-group col-md-3">
                            <label for="fecha_ini">Vigencia Póliza</label>
                            <input
                              type="date"
                              class="form-control form-control-sm"
                              id="vigencia_poliza"
                              v-model="form.vigencia_poliza"
                              :class="
                                $v.form.vigencia_poliza.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              :disabled="
                                !$store.getters.can(
                                  'hidrocarburos.contratos.edit'
                                )
                              "
                            />
                          </div>
                        </div>
                        <div class="row">                          
                          <div class="form-group col-md-3">
                            <label>Estado</label>
                            <select
                              class="form-control form-control-sm"
                              v-model="form.estado"
                              :class="
                                $v.form.estado.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              " 
                              disabled                           
                            >
                              <option value="">Seleccione...</option>
                              <option
                                v-for="estado in listasForms.estados"
                                :key="estado.numeracion"
                                :value="estado.numeracion"
                              >
                                {{ estado.descripcion }}
                              </option>
                            </select>
                          </div>

                          <div class="form-group col-md-4">
                            <label for="funcionarios">Admin Contrato</label>
                            <v-select
                              :class="[
                                $v.form.funcionario_id.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid',
                                $store.getters.getDarkMode
                                  ? 'dark-vselect'
                                  : '',
                              ]"
                              v-model="funcionario"
                              placeholder="Admin Contrato"
                              label="nombres"
                              class="form-control form-control-sm p-0"
                              :options="listasForms.funcionarios"
                              :filterable="false"
                              @search="buscarFuncionarios"
                              @input="selectFuncionarios()"
                              :disabled="
                                !$store.getters.can(
                                  'hidrocarburos.contratos.edit'
                                )
                              "
                            ></v-select>
                          </div>
                        </div>
                        <div class="form-group col-md-5">
                          <label for="observaciones">Observaciones</label>
                          <br />
                          <textarea
                            vtype="date"
                            class="form-control form-control-sm"
                            id="fecha_final"
                            v-model="form.observaciones"
                            rows="2"
                            :disabled="
                              !$store.getters.can(
                                'hidrocarburos.contratos.edit'
                              )
                            "
                          ></textarea>
                        </div>
                        <div class="form-group col-md-5" v-if="form.id">
                          <label for="link_documento"
                            >Copia contrato <sup>(PDF)</sup></label
                          >
                          <div
                            class="form-group"
                            v-if="form.link_documento == null"
                          >
                            <input
                              type="file"
                              id="link_documento"
                              ref="link_documento"
                              class="form-control-file"
                              accept=".pdf, .PDF"
                              @change="elegirDirectorio()"
                              :disabled="
                                !$store.getters.can(
                                  'hidrocarburos.contratos.edit'
                                )
                              "
                            />
                            <br />
                            <button
                              id="guardar_documento"
                              type="button"
                              @click="saveDocumento()"
                              class="btn btn-success"
                              data-html="true"
                              title="Guardar Documento"
                              data-toggle="tooltip"
                              :disabled="
                                !$store.getters.can(
                                  'hidrocarburos.contratos.edit'
                                )
                              "
                            >
                              <i class="fa fa-save"></i>
                            </button>
                          </div>
                          <div class="form-group">
                            <button
                              class="btn btn-secondary"
                              @click="descargarDocumento()"
                              id="BotonDescargar"
                              v-if="form.link_documento"
                              href=""
                            >
                              Descargar
                            </button>
                            <button
                              type="button"
                              class="btn btn-danger"
                              v-if="
                                $store.getters.can(
                                  'hidrocarburos.contratos.delete'
                                ) && form.link_documento
                              "
                              :disabled="
                                form.estado == 2 ||
                                !$store.getters.can(
                                  'hidrocarburos.contratos.edit'
                                )
                              "
                              @click="destroyContrato"
                            >
                              <i class="fas fa-trash-alt"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div
                          class="card card-outline card-navy col-md-12 m-2 p-2"
                        >
                          <div class="card-header pt-2 pb-2">
                            <h3 class="card-title">
                              <h6>
                                <i class="fas fa-users"></i>
                                Datos de contacto
                              </h6>
                            </h3>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label for="nombre">Nombre</label>
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                v-model="form.nombre"
                                :class="
                                  $v.form.nombre.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              />
                            </div>
                          </div>
                          <div class="col-md-12">
                            <label for="teléfono">Teléfono</label>
                            <input
                              type="number"
                              class="form-control form-control-sm"
                              v-model="form.teléfono"
                              :class="
                                $v.form.teléfono.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                            />
                          </div>
                          <div class="col-md-12 pt-2 pb-2">
                            <label for="email">Correo</label>
                            <input
                              type="email"
                              class="form-control form-control-sm"
                              v-model="form.correo"
                              :class="
                                $v.form.correo.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="Tarifarios">
                  <div class="card-body p-0">
                    <ContratoTarifario v-if="id != null"></ContratoTarifario>
                  </div>
                </div>
                <div class="tab-pane fade" id="TarifariosManuales">
                  <div class="card-body p-0">
                    <ContratoTarifarioManual
                      v-if="id != null"
                    ></ContratoTarifarioManual>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button
                    class="btn bg-primary col-md-1"
                    v-show="!$v.form.$invalid"
                    @click="save()"
                    v-if="
                      ($store.getters.can('hidrocarburos.contratos.create') ||
                        $store.getters.can('hidrocarburos.contratos.edit')) &&
                      estado != '2'
                    "
                  >
                    <i class="fas fa-paper-plane"></i><br />Guardar
                  </button>
                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="col-lg-3">
                <div class="small-box">
                  <div class="inner">
                    <h3 style="font-size: 45px">Valores</h3>
                    <h3 style="font-size: 14px">
                      <br />
                      Valor Presupuestado:
                      <span class="badge badge-pill badge-secondary">
                        {{ form.valor }}
                      </span>
                    </h3>
                    <h3 style="font-size: 14px">
                      Valor Efectuado:
                      <span class="badge badge-pill badge-success">
                        {{ form.valor_efectuado }}
                      </span>
                    </h3>
                    <h3 style="font-size: 14px">
                      <hr />
                      Diferencia:
                      <span class="badge badge-pill badge-dark">
                        {{ form.valor_diferencia }}
                      </span>
                    </h3>
                  </div>
                  <div class="icon">
                    <i class="ion ion-pie-graph"></i>
                  </div>
                  <a href="#" class="small-box-footer"></a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import { required, email } from "vuelidate/lib/validators";
import ContratoTarifario from "./ContratoTarifario";
import ContratoTarifarioManual from "./ContratoTarifarioManual";

export default {
  name: "ContratoForm",
  components: {
    vSelect,
    ContratoTarifario,
    ContratoTarifarioManual,
  },
  data() {
    return {
      form: {
        no_contrato: null,
        empresa_id: null,
        fecha_ini: null,
        fecha_fin: null,
        valor: null,
        estado: 1,
        funcionario_id: null,
        teléfono: null,
        nombre: null,
        correo: null,
      },
      accion: "",
      metodo: "",
      empresa: [],
      funcionario: [],
      listasForms: {
        estados: [],
        empresas: [],
        tipos_contrato: [],
        objetos_contrato: [],
        permisos_firmas: [],
        funcionarios: [],
      },
      id: null,
      estado: null,
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  validations: {
    form: {
      no_contrato: {
        required,
      },
      empresa_id: {
        required,
      },
      tipo_contrato: {
        required,
      },
      objeto_contrato: {
        required,
      },
      fecha_ini: {
        required,
      },
      fecha_fin: {
        required,
      },
      vigencia_poliza: {
        required,
      },
      valor: {
        required,
      },
      teléfono: {
        required,
      },
      correo: {
        required,
        email
      },
      nombre: {
        required,
      },
      funcionario_id: {
        required,
      },
      estado: {
        required,
      },
    },
  },
  methods: {
    init() {
      this.accion = this.$route.params.accion;
      this.id = this.$route.params.id;
      if (this.$route.params.data_edit) {
        this.estado = this.$route.params.data_edit.estado;
      }
      if (this.$route.params.accion == "Editar") {
        this.form = this.$route.params.data_edit;
        this.empresa = {
          id: this.form.empresa_id,
          razon_social: this.form.empresa.razon_social,
        };
        if (this.form.funcionario_id){
          this.funcionario = {
          id: this.form.funcionario_id,
          nombres:
            this.form.funcionario.nombres +
            " " +
            this.form.funcionario.apellidos,
        }; 
        }

        this.metodo = "PUT";
      } else {
        this.metodo = "POST";
      }
    },

    getEstados() {
      axios.get("/api/lista/28").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    getTipoContrato() {
      axios.get("/api/lista/26").then((response) => {
        this.listasForms.tipos_contrato = response.data;
      });
    },

    getObjetoContrato() {
      axios.get("/api/lista/27").then((response) => {
        this.listasForms.objetos_contrato = response.data;
      });
    },

    getUserFirmas() {
      axios
        .get("/api/hidrocarburos/contratos/getUserFirmas")
        .then((response) => {
          this.listasForms.permisos_firmas = response.data;
        });
    },

    validaFechas() {
      if (this.form.fecha_ini && this.form.fecha_fin) {
        const fecha_menor = new Date(this.form.fecha_ini);
        const fecha_mayor = new Date(this.form.fecha_fin);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.form.fecha_fin = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial no puede ser mayor a la fecha final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    descargarDocumento() {
      window.open(this.uri_docs + this.form.link_documento, "_blank");
    },

    saveDocumento() {
      let formData = new FormData();
      formData.append("id", this.form.id);
      formData.append("link_documento", this.link_documento);
      axios
        .post("api/hidrocarburos/contratos/documento", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.$swal({
            icon: "success",
            title: "La copia del contrato se guardaron exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.form.link_documento = response.data.link_documento;
        })
        .catch(function (error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    elegirDirectorio() {
      this.link_documento = this.$refs.link_documento.files[0];
    },

    buscarEmpresas(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/empresas/lista?razon=" + search;
        axios
          .get(url, {
            params: {
              linea_negocio_id: 2,
            },
          })
          .then(function (response) {
            let respuesta = response.data;
            me.listasForms.empresas = respuesta;
            me.form.empresa_id = me.listasForms.empresas.id;
            loading(false);
          })
          .catch(function (error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    selectEmpresa() {
      this.form.empresa_id = "";
      if (this.empresa) {
        this.form.empresa_id = this.empresa.id;
      }
    },

    buscarFuncionarios(search, loading) {
      if (search != "") {
        let me = this;
        var url = "api/admin/funcionarios/lista?nombre=" + search;
        me.form.funcionario_id = me.listasForms.funcionarios.id;
        axios
          .get(url)
          .then(function (response) {
            let respuesta = response.data;
            me.listasForms.funcionarios = respuesta;
            loading(false);
          })
          .catch(function (error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    save() {
      if (!this.$v.form.$invalid) {
        axios({
          method: this.metodo,
          url: "/api/hidrocarburos/contratos",
          data: this.form,
        })
          .then((response) => {
            this.form = response.data;
            (this.id = this.form.id),
              this.$swal({
                icon: "success",
                title: "El contrato se guardo exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    selectFuncionarios() {
      this.form.funcionario_id = "";
      if (this.funcionario) {
        this.form.funcionario_id = this.funcionario.id;
      }
    },

    destroyContrato() {
      this.$swal({
        title: "Está seguro de eliminar la copia del Contrato?",
        text: "Los cambios no se pueden revertir y eliminará las aprobaciones del contrato existentes!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios
            .delete("/api/hidrocarburos/contratos/destroyContrato/adjunto/", {
              params: {
                id: this.id,
                link: this.form.link_documento,
              },
            })
            .then(() => {
              this.form.link_documento = null;
              this.init();
              this.$swal({
                icon: "success",
                title: "El contrato se eliminó correctamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            })
            .catch(function (error) {
              this.$swal({
                icon: "error",
                title: "Ocurrio un error" + error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    back() {
      return this.$router.replace("/Hidrocarburos/Contratos");
    },
  },
  async mounted() {
    await this.init();
    await this.getEstados();
    await this.getTipoContrato();
    await this.getObjetoContrato();
    await this.getUserFirmas();
  },
};
</script>
